import { Amplify, Hub } from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'

import {
  hubSignUp,
  hubSignUpFailure,
  hubResendSignUp,
  hubResendSignUpFailure,
} from '../scripts/auth/sign-up'

import { hubSignIn, hubSignInFailure } from '../scripts/auth/sign-in'

import {
  hubForgotPassword,
  hubForgotPasswordFailure,
  hubForgotPasswordSubmit,
} from '../scripts/auth/reset'

import '../scripts/auth/activate'

var Pura = window.Pura || {}
window.Auth = Amplify.Auth = Auth
window.Amplify = Pura.Amplify = Amplify
var stage = !Pura.environment.production

Amplify.configure({
  Auth: {
    identityPoolId: stage
      ? 'us-east-1:6453fc73-c338-4dc0-88d4-97c3d94560df'
      : 'us-east-1:f277ac0f-2758-4647-9e45-5fb6c5799200',
    region: 'us-east-1',
    userPoolId: stage ? 'us-east-1_gTzleiu29' : 'us-east-1_LaB718hYv',
    userPoolWebClientId: stage
      ? '6n43s10do1victjnk4sg9j7sb4'
      : '4iekubat0jb5iljfbaalsiqf9j',
    mandatorySignIn: false,
    oauth: {
      domain: stage
        ? 'staging-pura.auth.us-east-1.amazoncognito.com'
        : 'auth.pura.co',
      scope: ['openid', 'profile', 'email'],
      redirectSignIn: 'https://pura.com/account/login?redirect',
      redirectSignOut: 'https://pura.com/account/logout',
      clientId: stage
        ? '6n43s10do1victjnk4sg9j7sb4'
        : '4iekubat0jb5iljfbaalsiqf9j',
      responseType: 'code',
    },
    authenticationFlowType: 'USER_SRP_AUTH',
  },
})

let event
let data

Hub.listen('auth', (res) => {
  if (res.payload) {
    event = res.payload.event || 'other'
    data = res.payload.data || {}
  } else {
    event = 'other'
    type = res.type
    message = res.message
  }

  switch (event) {
    case 'signIn':
      hubSignIn(data)
      break

    case 'signIn_failure':
      hubSignInFailure(data)
      break

    case 'signUp':
      hubSignUp(data)
      break

    case 'signUp_failure':
      hubSignUpFailure(data)
      break

    case 'resendSignUp':
      hubResendSignUp(data)
      break

    case 'resendSignUp_failure':
      hubResendSignUpFailure(data)
      break

    case 'forgotPassword':
      hubForgotPassword(data)
      break

    case 'forgotPassword_failure':
      hubForgotPasswordFailure(data)
      break

    case 'forgotPasswordSubmit':
      hubForgotPasswordSubmit(data)
      break

    case 'forgotPasswordSubmit_failure':
      hubForgotPasswordFailure(data)
      break

    default:
  }
})
