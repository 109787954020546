import { emailSignIn } from "./sign-in";


let resetEmail = null;

const sendResetCode = (email) => {
	resetEmail = email
	Auth.forgotPassword(email)
		.then((res) => {
			console.log("sendResetCode res ", res);
		})
		.catch((e) => {
			console.log("Password reset Reset Failed: ", e);
		});
};

const resetPassword = (email, code, password) => {
	Auth.forgotPasswordSubmit(email, code, password)
		.then((res) => {
			// Success: try logging in
			setTimeout(() => {
				emailSignIn(email, password)
			}, 500)
		})
		.catch((e) => {
			console.log("forgotPasswordSubmitFailed: ", e);
		});
};

const hubForgotPassword = (data) => {
	// Ignore if we're going through the account activation process
	if (location.pathname.indexOf('account/activate') > -1) return;

	if (resetEmail) {
		window.location = "/account/login?reset_email=" + encodeURIComponent(resetEmail);
		return;
	}
	window.location = "/account/login";
};

const hubForgotPasswordFailure = (data) => {
	$$('#password-reset-panel [data-content="response-message"]')
		.html(
			"There was a problem resetting your account. <br/.>Check your info and try again."
		)
		.removeClass("hidden");
	console.log("resetPasswordfailure", data);
};

const hubForgotPasswordSubmit = (data) => {
	console.log("forgotPasswordSubmit", data);
};

const hubForgotPasswordSubmitFailure = (data) => {
	console.log("forgotPasswordSumitFailure", data);
};

$$("#send-reset-code-form").on("submit", function (e) {
	e.preventDefault();
	let email = $$(e.target).find("#send-reset-email").val().trim();
	sendResetCode(email);
});

$$("#reset-password-form").on("submit", function (e) {
	e.preventDefault();
	let form = $$(e.target);
	let email = form.find("#reset-email").val().trim();
	let code = form.find("#reset-code").val().trim();
	let password = $$("#reset-new-password").val().trim();

	resetPassword(email, code, password);
});

const getURLParam = (name) => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	return urlParams.get(name);
};

let resetEmailField = document.getElementById("reset-email") || false;
let urlEmail = getURLParam("reset_email") || false;
if (resetEmailField && urlEmail) {
	resetEmailField.value = decodeURIComponent(urlEmail);
	var changeEvent = new Event("change", { bubbles: true });
	resetEmailField.dispatchEvent(changeEvent);
}


// setTimeout(() => {
//   if (Pura.template.name == 'activate_account') {
//     let email = decodeURIComponent( getURLParam('activate_email') )
//     console.log('activate account - ', email)
//     sendResetCode(email)
//     sendResetCode(email)
//   }
// }, 2500)





export { hubForgotPassword, hubForgotPasswordFailure, hubForgotPasswordSubmit, sendResetCode, resetPassword };
