import { getCookie, setCookie } from '../utilities/cookies'

const stage = !Pura.environment.production

const baseUrl = stage
  ? 'https://staging.trypura.io/mobile/api'
  : 'https://trypura.io/mobile/api'

const emailSignIn = (email, password) => {
  Auth.signIn(email, password)
    .then((res) => {
      // Successful
      return
    })
    .catch((e) => {
      // User exists but still needs to verify their email
      // Show verify panel
      if (e.code === 'UserNotConfirmedException') {
        if (window.Pura && window.Pura.accountPanel) {
          window.Pura.accountPanel.showVerifyPanel(email, password)
        }
        return
      }

      // If AWS login fails, try to migrate Shopify user to AWS
      checkMigration(email, password)
        .then((checkRes) => checkRes.json())
        .then((checkJson) => {
          if (!checkJson.retry) return
          // Try a second time
          Auth.signIn(email, password)
            .then((res2) => {
              console.log('res2 success', res2)
            })
            .catch((e2) => {
              console.log('e2 error', e2)
            })
        })
        .catch((checkError) => {
          console.log('check error', checkError)
          // setMessage('User Login Failed')
        })
    })
}

function checkMigration(email, password) {
  return fetch(`${baseUrl}/check_migration_needed`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  }).then((res) => {
    return res
  })
}

window.checkMigration = checkMigration
const hubSignIn = (data) => {
  const return_to_url = getCookie('login_redirect') || '/'
  const id_token = (data.signInUserSession || {}).idToken || {}

  const cognito_token = id_token.jwtToken
  const cognitoEmail = id_token.payload.email
  const cognitoFirebaseID = id_token.payload['custom:firebase_uid']

  if (!cognito_token) return console.error('No JWT token')

  if (typeof braze !== 'undefined') {
    braze.getUser().setEmail(cognitoEmail)
    braze.changeUser(cognitoFirebaseID)
  }

  fetch(`${baseUrl}/multipass`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: cognito_token,
    },
    body: JSON.stringify({ cognito_token, return_to_url }),
  })
    .then((r) => r.json())
    .then((res) => {
      location.href = res.signin_url
    })
    .catch((error) => {
      console.log('signing in error', error)
    })
}

const hubSignInFailure = (data) => {
  // Manual sign in
  // setMessage('User Login Failed')
  console.log('hubSignInFailure', data)

  switch (data.code) {
    case 'UserNotConfirmedException':
      console.log('show code panel')
      break

    case 'NotAuthorizedException':
      console.log('email or password wrong')
      $$('.sign-in-error')
        .removeClass('hidden')
        .text('email and password is incorrect')
      break
    default:
  }
}

// Using $$ to access cash-dom loaded from theme.min.js file
$$('#email-sign-in-form').on('submit', (e) => {
  e.preventDefault()
  const form = $$(e.target)
  const email = form.find('#signin-email').val().trim()
  const password = form.find('#signin-password').val()
  emailSignIn(email, password)
  setCookie('login_redirect', location.href, 1)
})

$$('.federated-signin-button').click(() => {
  setCookie('login_redirect', location.href, 1)
})

export { emailSignIn, hubSignIn, hubSignInFailure }
