
import { emailSignIn } from './sign-in'
/* 
{
   "Username":"coryschulz@temp.coryschulz.com",
   "Password":"Password1234",
   "UserAttributes":[
      {
         "Name":"name",
         "Value":"Cory Schulz"
      },
      {
         "Name":"given_name",
         "Value":"Cory"
      },
      {
         "Name":"family_name",
         "Value":"Schulz"
      }
   ]
}
*/


let signUpEmail

const getURLParam = (name) => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return urlParams.get(name)
}



const createUser = (firstName, lastName, email, password, submitButton) => {

  signUpEmail = email

  const data = {
    username: email,
    password,
    attributes: {
      name: `${firstName} ${lastName}`,
      given_name: firstName,
      family_name: lastName
    }
  }

  // Will trigger Hub: signUp event if successful
  Auth.signUp(data)
    .then(res => {
      console.log('signup Res is', res)
      if (window.Pura && window.Pura.accountPanel){
        window.Pura.accountPanel.showVerifyPanel(email, password)
      }
    })
    .catch(e => {
      submitButton.removeAttr('disabled')
    })
}


const confirmAccount = (email, password, code) => {

  Auth.confirmSignUp(email, code)
    .then(res => {
      // Confirm success - try login
      emailSignIn(email, password)
    })
    .catch(res => {
      console.log('confirmAccount catch', res)
    })

}


const resendCode = (email) => {
  console.log('resending code for email', email)
  Auth.resendSignUp(email)
    .then(res => {
      // console.log('resend response', res)
    })
    .catch(e => {
      console.error('resend response error', e)
    })
}



const hubSignUp = (data) => {
  // console.log('auth - sign-up - hubSignUp', data)
  // console.log('hubSignUp email', signUpEmail)
  // Don't do anything here
  // let params = ""
  // if (signUpEmail){
  //   params = "?email=" + signUpEmail 
  // }

}


const hubSignUpFailure = (data) => {
  let errorElement = document.getElementById('create-account-error-message')
  if (data.code == "UsernameExistsException"){
    errorElement.innerHTML = "An account with that email already exists."
  }else{
    errorElement.innerHTML = data.message
  }
  errorElement.classList.remove('hidden')
  console.log('auth - sign-up - hubSignUpFailure', data)
}



const hubResendSignUp = (data) => {
   console.log('Hub resend sign up')
}

const hubResendSignUpFailure = (data) => {
   console.error('Hub resend sign up failure')
}




// Using $$ to access cash-dom loaded from theme.min.js file 
$$('#account-sign-up-form').on('submit', function(e){
  e.preventDefault();
  let form = $$(e.target)

  // Prevent double submits
  let submitButton = form.find('[type="submit"]')
  submitButton.prop('disabled', true)

  let firstName = form.find('#sign-up-first-name').val().trim()
  let lastName = form.find('#sign-up-last-name').val().trim()
  let email = form.find('#sign-up-email').val().trim()
  let password = form.find('#sign-up-password').val().trim()
  createUser(firstName, lastName, email, password, submitButton)
})




$$('#account-verify-form').on('submit', function(e) {
  e.preventDefault();
  let form = $$(e.target)
  let email = form.find('#verification-email').val().trim()
  let password = form.find('#verification-password').val().trim()
  let code = form.find('#verification-code').val().trim()
  confirmAccount(email, password, code)
})


$$('[data-action="resend-verification-code"]').click( function(e) {
  e.preventDefault();
  const email = $$('#verification-email').val()
  resendCode(email)
  $$('#account-verify-form [data-content="response-message"]').html("Check your email for a new code.").removeClass('hidden')
})


$$('[data-action="reset-password-resend-code"]').click( function(e) {
  e.preventDefault();
  console.log('reset-password-resend-code button clicked')
  resendCode( document.getElementById('reset-email').value )
  $$('#password-reset-panel [data-content="response-message"]').html("Check your email for a new code.").removeClass('hidden')
})



if ( location.pathname == "/account/register" ) {
  let email = getURLParam('email')
  let code = getURLParam('code')
  
  // Auto submit form if we have both
  if (email != null && code != null){
    setTimeout(function(){
      confirmAccount(email, code)
    }, 600)
  }


  if (email != null){
    $$('#verification-email').val(email)
    $$('#verification-email').trigger('change')
  }
  if (code != null){
    $$('#verification-code').val(code)
    $$('#verification-code').trigger('change')
  }


}


export { createUser, resendCode, hubSignUp, hubSignUpFailure, hubResendSignUp, hubResendSignUpFailure }


