import { emailSignIn } from './sign-in'
import { sendResetCode, resetPassword } from './reset'

/*
Must include this data:

form_type: activate_customer_password
utf8: ✓
customer[password]: Apple1
customer[password_confirmation]: Apple1
token: dbfd00d37d0013e51bd4e085ee580f66-1681765522
id: 6354605670509


form_type=activate_customer_password&utf8=%E2%9C%93&customer%5Bpassword%5D=Apple1&customer%5Bpassword_confirmation%5D=Apple1&token=cc29554cec674b9990e9be545a371923-1681765799&id=6354625593453

form_type=activate_customer_password&utf8=%E2%9C%93&customer%5Bpassword%5D=Apple1&customer%5Bpassword_confirmation%5D=Apple1&token=43f75f3294b69f63eafeb786115787ce-1681859120&id=6355528548461

*/

let activatePassword, activateEmail

const getURLParam = (name) => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return urlParams.get(name)
}

const activateOnShopify = (password, id, token) => {
  // new URLSearchParams(Object.entries(dataObj)).toString();

  password = encodeURIComponent(password)

  let body = `form_type=activate_customer_password&utf8=%E2%9C%93&customer%5Bpassword%5D=${password}&customer%5Bpassword_confirmation%5D=${password}&token=${token}&id=${id}`

  return fetch('/account/activate', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    body: body,
  })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      console.log('activateOnShopify response', response)
      return response
    })
    .catch((error) => {
      console.error('activate error', error)
    })
}

$$('#account-activation-form').on('submit', function (e) {
  e.preventDefault()

  activatePassword = this.querySelector('#activate-password').value
  let token = this.querySelector('[name="token"]').value
  let id = this.querySelector('[name="id"]').value
  activateEmail = decodeURIComponent(getURLParam('activate_email')).trim()

  activateOnShopify(activatePassword, id, token).then((res) => {
    if (!res.ok) return
    // Send verification code
    setTimeout(() => {
      sendResetCode(activateEmail)
    }, 200)

    // Hide activation form, show verification form
    document.getElementById('activate-password-section').classList.add('hidden')
    document.getElementById('activate-code-section').classList.remove('hidden')
  })
})

$$('#account-verify-code-form').on('submit', function (e) {
  e.preventDefault()
  let code = $$('#verification-code').val()
  resetPassword(activateEmail, code, activatePassword)
})
